.chakra-portal .chakra-modal__content-container {
  z-index: 99999;
}

img {
  -drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noSelect {
  -drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noTap {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}

.canvas {
  position: relative;
  top: 0;
  left: 0;
  border: 0px solid #000;
  width: 480px;
  max-width: 80%;
  margin: auto;
  height: auto;
}

#simon-parent,
.game-container,
#simon-parent > canvas {
  position: relative;
  top: 0;
  left: 0;
  margin: auto;
  height: auto;
}

#simon-parent {
  border: 0px solid #000;
  max-width: 480px;
}

#simon-parent > canvas {
  object-fit: contain;
  max-width: 100%;
  cursor: pointer;
}
