.carousel .control-dots {
  list-style: none;
  margin: -5px 0;
  overflow: hidden;
}
li.carouselItem::before {
  font-family: "King Of The Hill 2",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 200px;
  content: ".";
  line-height: 0px;
  color: #999999;
}
li.carouselItem.selected::before {color: #000000;}

.carousel .control-prev.control-arrow:before {
  border-right-color: #000000;
}

.carousel .control-next.control-arrow:before {
  border-left-color: #000000;
}

.leaderboardTable,
.leaderboardTable th,
.leaderboardTable td,
.leaderboardText {
  color: white;
}

.leaderboardTable,
.leaderboardTable th,
.leaderboardTable td {
  font-size: 15px;
  font-family: "Arcade Classic",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.leaderboardText {
  font-size: 15px;
  font-family: "King Of The Hill 2",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}