.guy-avatar {
  transition: all 500ms ease-in-out;
}

.guy-avatar > img {
  border-radius: 0;
  transition: all 500ms cubic-bezier(0.135, 0.795, 0.585, 1.645);
  transition-timing-function: cubic-bezier(
    0.135,
    0.795,
    0.585,
    1.645
  );
}

.guy-avatar.avatar-joint {
  min-width: 180px;
  min-height: 180px;
}
.guy-avatar.avatar-joint > img {
  transition: transform 0.5s ease-in-out;
}

.guy-avatar:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: scale(0.9) translateZ(0);
}

.guy-avatar:hover > img {
  transform: scale(1.8) translateZ(0);
}

.guy-avatar.avatar-joint:hover > img {
  transform: rotate(360deg) scale(1.8) translateZ(0);
}

.guy-avatar.avatar-eyes {
  /* min-width: 243px;
  min-height: 243px; */
}

.guy-avatar.avatar-eyes:hover > img {
  animation-name: wobble;
  animation-duration: 1.2s;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

@media only screen and (min-width: 768px) {
}

@media only screen and (max-width: 767px) {
}


@keyframes wobble {
  0% {
    transform: translateX(0%) scale(0.1);
  }
  15% {
    transform: translateX(-25%) rotate(-5deg) scale(0.3);
  }
  30% {
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    transform: translateX(-15%) rotate(-3deg) scale(0.6);
  }
  60% {
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg) scale(2.5);
  }
  100% {
    transform: translateX(0%) scale(1.5);
  }
}
