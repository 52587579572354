
.cards-container {
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
}

.slot-machine ul {
  list-style-type: none;
  position: relative;
  width: 100%;
  height: 95px;
  bottom: 0;
  transition: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  transform: translateZ(0);
}

/* .slot-machine ul.top-track {
  width: 100%;
  bottom: 0;
  justify-content: flex-start;
  margin-left: 1065px;
  z-index: 10000;
} */
/* .slot-machine .spinResultTop {
  right: -103px;
}
.slot-machine .spinResultBottom {
  right: -420px;
} */
