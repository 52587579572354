.swiper {
  width: 100%;
  height: 100%;
  background-color: black;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 0px) {
  .swiper {
    --swiper-navigation-color: rgba(255,255,255,0.0);
  }
}

@media (min-width: 768px) {
  .swiper {
    --swiper-navigation-color: rgba(255,255,255,0.3);
  }
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: white;
}

.swiper-slide img,
.swiper-slide a img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}

/* target the active bullet */
.carouselSwiper span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
  opacity: 1;
}

/* target all bullets */
.carouselSwiper .swiper-pagination-bullet {
  background-color: #999999;
  opacity: 1;
}