.teamBox .chakra-avatar {
   overflow: hidden; 
}

.teamBox .chakra-avatar img {
  transition: transform .5s ease, filter .5s ease;
  filter: brightness(100%);
}

.teamBox .chakra-avatar:hover img {
  transform: scale(1.2);
  filter: brightness(110%);
}
