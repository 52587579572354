.teamBox .chakra-avatar {
   overflow: hidden; 
}

.roadmap2022Box .roadmapItem {
  transition: all 175ms ease-in-out;
  /* transition: transform .2s ease, filter .2s ease; */
  filter: brightness(100%);
}

.roadmap2022Box .roadmapItem:hover {
  transform: scale(1.35);
  filter: brightness(150%);
}

.roadmap2022Box .roadmapItem:hover {
  /* animation-name: wobble;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  backface-visibility: hidden; */
}

.roadmap2022Box .chakra-popover__popper:focus-visible,
.chakra-popover__content:focus-visible,
.roadmap2022Box .chakra-popover__popper:focus,
.chakra-popover__content:focus {
  
  box-shadow: none;
  outline: none;
}

.roadmap2022Box .chakra-popover__popper section:focus-visible,
.chakra-popover__content section:focus-visible,
.roadmap2022Box .chakra-popover__popper section:focus,
.chakra-popover__content section:focus {
  box-shadow: 0 0 0 3px rgba(128, 128, 128, 0.6) !important;
  outline: 1px rgba(128, 128, 128, 0.6) !important;
}



@keyframes wobble {
  0% {
    transform: translateX(0%) scale(1.0);
  }
  50% {
    transform: translateX(-15%) rotate(-3deg) scale(0.6);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg) scale(2.5);
  }
  100% {
    transform: translateX(0%) scale(1.5);
  }
}